import { child, equalTo, get, onChildAdded, orderByChild, push, query, ref, remove, set, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db, storage } from '../../firebase'
import { getDownloadURL, uploadBytes, ref as sRef, uploadString, uploadBytesResumable } from 'firebase/storage'
import { Link } from 'react-router-dom'
import Papa from 'papaparse';
import axios from 'axios'

const AdminReports = () => {

    const [loader, setLoader] = useState(false)
    const [reportsUploadSuccess, setReportsUploadSuccess] = useState(false)
    const [reportsUpdateSuccess, setReportsUpdateSuccess] = useState(false)
    const [reportsUploadProgress, setReportsUploadProgress] = useState(0)
    const [reportsUpdateProgress, setReportsUpdateProgress] = useState(0)
    const [users, setUsers] = useState([])
    const [releases, setReleases] = useState([])
    const [uploadReportsMessage, setUploadReportsMessage] = useState('Upload Reports')
    const [fileUpload, setFileUpload] = useState({})
    const [fileReports, setFileReports] = useState([])
    const [fileHeaders, setFileHeaders] = useState([])
    const [dropdowns, setDropdowns] = useState(false)
    const [overviewObjects, setOverviewObjects] = useState([])
    const [reports, setReports] = useState([])
    const [errors, setErrors] = useState([])
    const [adminBalance, setAdminBalance] = useState(0)
    const [topAdminChannels, setTopAdminChannels] = useState([])
    const [topAdminReleases, setTopAdminReleases] = useState([])
    const [topAdminCountries, setTopAdminCountries] = useState([])
    const [adminGroupedReleases, setAdminGroupedReleases] = useState([])
    const [adminReports, setAdminReports] = useState([])
    
    const [headers, setHeaders] = useState([
        {
            title: 'Statement Period',
            mapping: 'Statement Period',
            uploaded: ''
        },
        {
            title: 'Transaction Month',
            mapping: 'Transaction Month',
            uploaded: ''
        },
        {
            title: 'Artist',
            mapping: 'Artist',
            uploaded: ''
        },
        {
            title: 'Label',
            mapping: 'Label',
            uploaded: ''
        },
        {
            title: 'Platform',
            mapping: 'Platform',
            uploaded: ''
        },
        {
            title: 'Release Title',
            mapping: 'Release Title',
            uploaded: ''
        },
        {
            title: 'Track Title',
            mapping: 'Track Title',
            uploaded: ''
        },
        {
            title: 'UPC',
            mapping: 'UPC',
            uploaded: ''
        },
        {
            title: 'ISRC',
            mapping: 'ISRC',
            uploaded: ''
        },
        {
            title: 'Units',
            mapping: 'Quantity',
            uploaded: ''
        },
        {
            title: 'Payee ID',
            mapping: 'Payee ID',
            uploaded: ''
        },
        {
            title: 'Sale Type',
            mapping: 'Sale Type',
            uploaded: ''
        },
        {
            title: 'Territory',
            mapping: 'Territory',
            uploaded: ''
        },
        {
            title: 'Earnings',
            mapping: 'Earnings',
            uploaded: ''
        }
    ])

    const reportsSuccess = useRef()
    const errorBtn = useRef()


    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray)
            const releasesArray = []
            usersArray.forEach(user => {
                if (user.releases) {
                    const liveReleases = user.releases.filter(item => item.status === "Distributed")
                    releasesArray.push(...liveReleases)
                }
            })
            setReleases(releasesArray)
            // updateTracks(usersArray)
            usersArray.forEach(user => {
                // if (user.inviteSplits) console.log(user.id)
                // if (user.reports) {
                //     let userBalance = user.balance * 1
                //     const lastMonth = user.reports.find(item => item.monthYear === '9/2024')
                //     if (lastMonth) userBalance -= lastMonth.totalPrice
                //     const userReports = user.reports.filter(item => item.monthYear !== '9/2024')
                //     update(child(ref(db, 'users'), user.id), {
                //         reports: userReports,
                //         balance: userBalance
                //     })
                // }
            //     const userID = user.customID
                // if (user.releases) {
                //     let newReleases = user.releases
                //     for(let i = 0; i < newReleases.length; i++) {
                //         if (newReleases[i].status === 'Distributed') {
                //             newReleases[i].wasLive = true
                //         }
                //     }
                //     update(child(ref(db, 'users'), user.id), {
                //         releases: newReleases
                //     })
                // }
                    // user.releases.forEach(release => {
                    //     if (release.tracks) {
                    //         release.tracks.forEach(track => {
                    //             track.copyright = ''
                    //         })
                    //     }
                    // })
                    // update(child(ref(db, 'users'), user.id), {
                    //     releases: user.releases
                    // })
                    // const userOverview = user.reportsOverview || []
                    // const newOverview = userOverview.filter(item => item.monthYear !== '2024-03')
                // update(child(ref(db, 'users'), user.id), {
                //     groupedReleases: [],
                //     sorting: [],
                //     reports: [],
                //     newReports: [],
                //     balance: 0,
                //     reportsOverview: [],
                // })
            //     if (user.id === 'iT3W6acHzxgQyjlIgVcyf1i53E43') {
            //         update(child(ref(db, 'users'), 'iT3W6acHzxgQyjlIgVcyf1i53E43), {
            //          role: 'super-admin'
            //      })
                // }
            })
            // remove(ref(db, 'track_histories'))
            // remove(ref(db, 'reports'))
            // remove(ref(db, 'users_reports'))
            // remove(ref(db, 'uploaded_reports'))
            // remove(ref(db, 'admin'))
            // set(push(ref(db, 'upcs')), {
            //     upc : '1'
            // })
        })
        get(child(ref(db, 'admin'), 'reports'))
        .then(snapshot => {
            if (snapshot.exists()) {
                setAdminBalance(snapshot.val().balance || 0)
                setTopAdminChannels(snapshot.val().sorting.channels || [])
                setTopAdminReleases(snapshot.val().sorting.releases || [])
                setTopAdminCountries(snapshot.val().sorting.countries || [])
                setAdminGroupedReleases(snapshot.val().groupedReleases || [])
                setAdminReports(snapshot.val().reports || [])
            }
        })
    }, [])

    const updateTracks = (userArray) => {
        userArray.forEach(user => {
            if (user.releases) {
                const userReleases = []
                user.releases.forEach(releaseItem => {
                    // const releaseTracks = []
                    // if (releaseItem.tracks) {
                    //     releaseItem.tracks.forEach(track => {
                    //         if (track.id) {
                    //             releaseTracks.push(track)
                    //         } else {
                    //             releaseTracks.push({
                    //                 id: Math.random().toString(36).substr(2, 9),
                    //                 ...track
                    //             })
                    //         }
                    //     })
                    // }
                    userReleases.push({
                        ...releaseItem,
                        originalDate: reformatDate(releaseItem.originalDate),
                        digitalDate: reformatDate(releaseItem.digitalDate)
                    })
                })
                update(child(ref(db, 'users'), user.id), {
                    releases: userReleases
                })
            }
        })
    }

    function reformatDate(dateString) {
        
        if (dateString.includes('-')) {
            return dateString.split('-').join('/')
        } else if (dateString !== '') {
            const splitted = dateString.split('/')
    
            if (splitted[0].length < 3 && splitted[splitted.length-1].length < 3) {
                if (splitted[splitted.length-1] * 1 > 24) {
                    splitted[splitted.length-1] = '19' + splitted[splitted.length-1]
                } else {
                    splitted[splitted.length-1] = '20' + splitted[splitted.length-1]
                }
        
                if (splitted[0].length === 1) splitted[0] = '0' + splitted[0]
                if (splitted[1].length === 1) splitted[1] = '0' + splitted[1]

                return splitted.reverse().join('/')
        
            } else if (splitted[splitted.length-1].length === 4) {
                return splitted.reverse().join('/')
            } 

        }

        return dateString
                
    }

    const sortChannels = (data, payout, prevChannels) => {
        const channelRevenueMap = data.reduce((acc, report) => {
            // const { channel, taxesnet_total_client_currency } = report;
            const channel = report['Platform']
            const earnings = report['Earnings']
            acc[channel] = (acc[channel] || 0) + earnings * 1;
            return acc;
        }, {});
        const sortedChannels = Object.keys(channelRevenueMap).sort((a, b) => {
            return channelRevenueMap[b] - channelRevenueMap[a];
        });
        const resultObjects = sortedChannels.map(channel => {
            return {
              channel: channel,
              totalRevenue: channelRevenueMap[channel] * payout,
              // ... other properties you want to include in the result object
            };
        });

        const combined = [...resultObjects, ...prevChannels]

        let mergedChannels = {}

        combined.forEach(item => {
            mergedChannels[item.channel] = mergedChannels[item.channel] + item.totalRevenue || item.totalRevenue
        })

        const merged = []

        for (let channel in mergedChannels) {
            merged.push({
                channel: channel,
                totalRevenue: mergedChannels[channel]
            })
        }
        
        const sortedResult = merged.sort((a, b) => b.totalRevenue - a.totalRevenue)
        const topFour = sortedResult.slice(0, 4)
        
        return topFour
    }

    const sortAdminChannels = (data) => {
        const currentAdminChannels = topAdminChannels.map(channel => {
            return {
                "Platform": channel.channel,
                "Earnings": channel.totalRevenue
            }
        })
        const channelRevenueMap = data.reduce((acc, report) => {
            // const { channel, taxesnet_total_client_currency } = report;
            const channel = report['Platform']
            const earnings = report['Earnings']
            acc[channel] = (acc[channel] || 0) + earnings * 1;
            return acc;
        }, {});
        const newChannels = Object.entries(channelRevenueMap).map(([key, item]) => {
            return {
                "Platform": key,
                "Earnings": item
            }
        })
        const totalChannelRevenue = [...currentAdminChannels, ...newChannels]
        const totalChannelRevenueMap = totalChannelRevenue.reduce((acc, report) => {
            // const { channel, taxesnet_total_client_currency } = report;
            const channel = report['Platform']
            const earnings = report['Earnings']
            acc[channel] = (acc[channel] || 0) + earnings * 1;
            return acc;
        }, {});
        const newTotalChannels = Object.entries(totalChannelRevenueMap).map(([key, item]) => {
            return {
                "Platform": key,
                "Earnings": item
            }
        })
        const sortedChannels = newTotalChannels.sort((a, b) => b['Earnings'] - a['Earnings']);
        const topChannels = sortedChannels.slice(0, 4);
        const resultObjects = topChannels.map(channel => {
            return {
              channel: channel["Platform"],
              totalRevenue: channel["Earnings"],
              // ... other properties you want to include in the result object
            };
        });
        return resultObjects
    }


    const sortReleases = (data, payout, prevReleases) => {
        const releaseRevenueMap = data.reduce((acc, report) => {
            // const { release, taxesnet_total_client_currency } = report;
            const release = report['Release Title']
            const earnings = report['Earnings']
            acc[release] = (acc[release] || 0) + earnings * 1;
            return acc;
        }, {});
        const sortedReleases = Object.keys(releaseRevenueMap).sort((a, b) => {
            return releaseRevenueMap[b] - releaseRevenueMap[a];
        });
        const resultObjects = sortedReleases.map(release => {
            return {
              release: release,
              totalRevenue: releaseRevenueMap[release] * payout,
              // ... other properties you want to include in the result object
            };
        });

        const combined = [...resultObjects, ...prevReleases]

        let mergedReleases = {}

        combined.forEach(item => {
            mergedReleases[item.release] = mergedReleases[item.release] + item.totalRevenue || item.totalRevenue
        })

        const merged = []

        for (let release in mergedReleases) {
            merged.push({
                release: release,
                totalRevenue: mergedReleases[release]
            })
        }
        
        const sortedResult = merged.sort((a, b) => b.totalRevenue - a.totalRevenue)
        const topFour = sortedResult.slice(0, 4)

        return topFour
    }

    const sortAdminReleases = (data) => {
        const currentAdminReleases = topAdminReleases.map(release => {
            return {
                "Release Title": release.release,
                "Earnings": release.totalRevenue
            }
        })
        const releaseRevenueMap = data.reduce((acc, report) => {
            // const { channel, taxesnet_total_client_currency } = report;
            const release = report['Release Title']
            const earnings = report['Earnings']
            acc[release] = (acc[release] || 0) + earnings * 1;
            return acc;
        }, {});
        const newReleases = Object.entries(releaseRevenueMap).map(([key, item]) => {
            return {
                "Release Title": key,
                "Earnings": item
            }
        })
        const totalReleaseRevenue = [...currentAdminReleases, ...newReleases]
        const totalReleaseRevenueMap = totalReleaseRevenue.reduce((acc, report) => {
            // const { channel, taxesnet_total_client_currency } = report;
            const release = report['Release Title']
            const earnings = report['Earnings']
            acc[release] = (acc[release] || 0) + earnings * 1;
            return acc;
        }, {});
        const newTotalReleases = Object.entries(totalReleaseRevenueMap).map(([key, item]) => {
            return {
                "Release Title": key,
                "Earnings": item
            }
        })
        const sortedReleases = newTotalReleases.sort((a, b) => b['Earnings'] - a['Earnings']);
        const topReleases = sortedReleases.slice(0, 4);
        const resultObjects = topReleases.map(release => {
            return {
              release: release["Release Title"],
              totalRevenue: release["Earnings"],
              // ... other properties you want to include in the result object
            };
        });
        return resultObjects
    }

    const sortCountries = (data, payout, prevCountries) => {
        const countryRevenueMap = data.reduce((acc, report) => {
            // const { country, taxesnet_total_client_currency } = report;
            const country = report['Territory']
            const earnings = report['Earnings']
            acc[country] = (acc[country] || 0) + earnings * 1;
            return acc;
        }, {});
        const sortedCountries = Object.keys(countryRevenueMap).sort((a, b) => {
            return countryRevenueMap[b] - countryRevenueMap[a];
        });
        const resultObjects = sortedCountries.map(country => {
            return {
              country: country,
              totalRevenue: countryRevenueMap[country] * payout,
              // ... other properties you want to include in the result object
            };
        });
        
        const combined = [...resultObjects, ...prevCountries]

        let mergedCountries = {}

        combined.forEach(item => {
            mergedCountries[item.country] = mergedCountries[item.country] + item.totalRevenue || item.totalRevenue
        })

        const merged = []

        for (let country in mergedCountries) {
            merged.push({
                country: country,
                totalRevenue: mergedCountries[country]
            })
        }
        
        const sortedResult = merged.sort((a, b) => b.totalRevenue - a.totalRevenue)
        const topFour = sortedResult.slice(0, 4)

        return topFour
    }


    const sortAdminCountries = (data) => {
        const currentAdminCountries = topAdminCountries.map(country => {
            return {
                "Territory": country.country,
                "Earnings": country.totalRevenue
            }
        })
        const countryRevenueMap = data.reduce((acc, report) => {
            // const { channel, taxesnet_total_client_currency } = report;
            const country = report['Territory']
            const earnings = report['Earnings']
            acc[country] = (acc[country] || 0) + earnings * 1;
            return acc;
        }, {});
        const newCountries = Object.entries(countryRevenueMap).map(([key, item]) => {
            return {
                "Territory": key,
                "Earnings": item
            }
        })
        const totalcountryRevenue = [...currentAdminCountries, ...newCountries]
        const totalcountryRevenueMap = totalcountryRevenue.reduce((acc, report) => {
            // const { channel, taxesnet_total_client_currency } = report;
            const country = report['Territory']
            const earnings = report['Earnings']
            acc[country] = (acc[country] || 0) + earnings * 1;
            return acc;
        }, {});
        const newTotalCountries = Object.entries(totalcountryRevenueMap).map(([key, item]) => {
            return {
                "Territory": key,
                "Earnings": item
            }
        })
        const sortedCountries = newTotalCountries.sort((a, b) => b['Earnings'] - a['Earnings']);
        const topCountries = sortedCountries.slice(0, 4);
        const resultObjects = topCountries.map(country => {
            return {
              country: country["Territory"],
              totalRevenue: country["Earnings"],
              // ... other properties you want to include in the result object
            };
        });
        return resultObjects
    }



    /////// GROUP RELEASES ///////
    const groupReleases = (reports, payout) => {
        const transformedTracks = Object.values(
            reports.reduce((acc, report) => {
            //   const { release, upc, units, track: track, taxesnet_total_client_currency, isrc } = report;
              const release = report["Release Title"]
              const upc = report['UPC']
              const units = report['Quantity']
              const track = report['Track Title'] || ''
              const earnings = report['Earnings'] * payout
              const isrc = report['ISRC'] || ''

              if (!acc[upc]) {
                acc[upc] = {
                  release,
                  upc,
                  revenue: 0,
                  streams: 0,
                  tracks: [],
                };
              }
              acc[upc].revenue += earnings * 1;
              acc[upc].streams += units * 1;
              acc[upc].tracks.push({ track: track, streams: units, revenue: earnings, isrc });
              return acc;
            }, {})
        );
        return transformedTracks
    }
   
   
    const groupAdminReleases = (reports) => {
        let currentReleases = []
        adminGroupedReleases.forEach(release => {
            release.tracks.forEach(track => {
                currentReleases.push({
                    'Release Title': release.release,
                    'UPC': release.upc,
                    'Quantity': track.streams,
                    'Earnings': track.revenue,
                    'ISRC': track.isrc || '',
                    'Track Title': track.track || ''
                })
            })
        })

        const groupedReports = [...currentReleases, ...reports]


        const transformedTracks = Object.entries(
            groupedReports.reduce((acc, report) => {
            //   const { release, upc, units, track: track, taxesnet_total_client_currency, isrc } = report;
              const release = report["Release Title"]
              const upc = report['UPC']
              const units = report['Quantity']
              const track = report['Track Title'] || ''
              const earnings = report['Earnings']
              const isrc = report['ISRC'] || ''

              if (!acc[upc]) {
                acc[upc] = {
                  release,
                  upc,
                  revenue: 0,
                  streams: 0,
                  tracks: [],
                };
              }
              acc[upc].revenue += earnings * 1;
              acc[upc].streams += units * 1;
              acc[upc].tracks.push({ track: track, streams: units, revenue: earnings, isrc });
              return acc;
            }, {})
        );

        const groupedTracks = transformedTracks.map(([upc, item]) => {
            return {
                upc: upc,
                ...item
            }
        })
        return groupedTracks
    }

    const groupTracksByISRC = (reports) => {
        const groupedReports = reports.map((report) => {
          const groupedTracks = {};
      
          report.tracks.forEach((item) => {
            const { track, streams, revenue, isrc } = item;
            // const track = item['Track Title']
            // const streams = item['Quantity']
            // const revenue = item['Earnings']
            // const isrc = item['ISRC']
      
            if (!groupedTracks[isrc]) {
              groupedTracks[isrc] = { track, streams: 0, revenue: 0, isrc, share: 100 };
            }
      
            groupedTracks[isrc].streams += streams * 1;
            groupedTracks[isrc].revenue += revenue * 1;
          });

          const reportUPC = report.upc.replace("'", "") * 1
      
          return { release: report.release, streams: report.streams, upc: reportUPC, revenue: report.revenue, tracks: Object.values(groupedTracks) };
        });
      
        return groupedReports;
    };

    const mergeReleases = (array1, array2) => {
        const mergedMap = new Map();
      
        // Helper function to accumulate tracks
        function accumulateTracks(tracks1, tracks2) {
          const trackMap = new Map();
      
          tracks1.forEach(track => trackMap.set(track.isrc, { ...track }));
          tracks2.forEach(track => {
            if (trackMap.has(track.isrc)) {
              const existingTrack = trackMap.get(track.isrc);
              existingTrack.revenue += track.revenue;
              existingTrack.streams += track.streams;
            } else {
              trackMap.set(track.isrc, { ...track });
            }
          });
      
          return Array.from(trackMap.values());
        }
      
        // Helper function to add or update release in the map
        function addOrUpdateRelease(release) {
          if (mergedMap.has(release.upc)) {
            const existingRelease = mergedMap.get(release.upc);
            existingRelease.revenue += release.revenue;
            existingRelease.streams += release.streams;
            existingRelease.tracks = accumulateTracks(existingRelease.tracks, release.tracks);
          } else {
            mergedMap.set(release.upc, { ...release });
          }
        }
      
        // Process the first array
        array1.forEach(addOrUpdateRelease);
      
        // Process the second array
        array2.forEach(addOrUpdateRelease);
      
        // Convert map back to array
        return Array.from(mergedMap.values());
    }

    const groupAdminTracksByISRC = (reports) => {
        const groupedReports = reports.map((report) => {
          const groupedTracks = {};
      
          report.tracks.forEach((item) => {
            const { track, streams, revenue, isrc } = item;
            // const track = item['Track Title']
            // const streams = item['Quantity']
            // const revenue = item['Earnings']
            // const isrc = item['ISRC']
      
            if (!groupedTracks[isrc]) {
              groupedTracks[isrc] = { track, streams: 0, revenue: 0, isrc };
            }
      
            groupedTracks[isrc].streams += streams * 1;
            groupedTracks[isrc].revenue += revenue * 1;
          });
      
          return { release: report.release, streams: report.streams, revenue: report.revenue, upc: report.upc, tracks: Object.values(groupedTracks) };
        });
      
        return groupedReports;
    };

    // const groupSplits = (tracks, splits) => {
    //     const groupedTracks = [];

    //     // Iterate over each track
    //     tracks.forEach((track) => {
    //         const { isrc, taxesnet_total_client_currency } = track;

    //         // Check if the isrc already exists in groupedTracks
    //         const existingTrack = groupedTracks.find((groupedTrack) => groupedTrack.isrc === isrc);

    //         // If the isrc doesn't exist, create a new entry
    //         if (!existingTrack) {
    //             groupedTracks.push({ isrc, total_price: taxesnet_total_client_currency * 1 });
    //         } else {
    //             // If the isrc exists, update the total price
    //             existingTrack.total_price += taxesnet_total_client_currency * 1;
    //         }
    //     });

    //     groupedTracks.forEach((track) => {

    //     })
    // }


    let collabReports = []
    


    ///// ORDER MONTHS /////    
    const sortArrayByMonthYear = (array) => {
        return array.sort((a, b) => {
            const [aMonth, aYear] = a.monthYear.split('/').map(Number);
            const [bMonth, bYear] = b.monthYear.split('/').map(Number);
    
            // Compare years first
            if (aYear !== bYear) {
                return aYear - bYear;
            }
    
            // If years are the same, compare months
            return aMonth - bMonth;
        });
    }



    const groupUsersByEmail = (users) => {
        const groupedUsers = users.reduce((accumulator, user) => {
          const existingUser = accumulator.find((groupedUser) => groupedUser.email === user.user);
      
          if (existingUser) {
            // If user with the same email already exists in the accumulator, update the balance
            existingUser.balance += user.revenueShare * 1;
            existingUser.key = user.key
            existingUser.tracks.push({ 
                revenueShare: user.revenueShare, 
                isrc: user.isrc,
                isRecoupment: user.isRecoupment,
                percentage: user.percentage,
                title: user.title,
                user: user.user
            });
          } else {
            // If user with the same email does not exist, create a new grouped user
            const newUser = {
              key: user.key,
              email: user.user,
              balance: user.revenueShare * 1,
              tracks: [...user.inviteSplits, { 
                revenueShare: user.revenueShare, 
                isrc: user.isrc,
                title: user.title,
                isRecoupment: user.isRecoupment,
                percentage: user.percentage,
                user: user.user
            }],
            };
            accumulator.push(newUser);
          }
      
          return accumulator;
        }, []);
      
        return groupedUsers;
      };

    const groupCollaborators = (array) => {
        const newCollabs = []
        let count = 0
        array.forEach(collab => {
            newCollabs.push({
                key: collab.userID,
                // balance: user.balance * 1 || 0,
                // inviteSplits: user.inviteSplits || [],
                balance: 0,
                inviteSplits: [],
                ...collab
            })
            count++
        })

        if (count === array.length) {
            return groupUsersByEmail(newCollabs)
        }
    }

    const calculateRevenueShares = (releases, splits, payout) => {
        const collaborators = [];
        let minusRevenue = 0

        // Iterate through releases
        releases.forEach(release => {
            // Iterate through tracks in each release
            release.tracks.forEach(track => {

                const trackRevenue = track.revenue * payout
                
                const recoupmentUserIndex = splits.findIndex(
                    (split) => split.track === track.isrc && split.recoupment * 1 > 0
                );
          
                if (recoupmentUserIndex !== -1) {
                    const recoupmentUser = splits[recoupmentUserIndex];
                    const recoupmentAmount = Math.min(recoupmentUser.recoupment * 1, trackRevenue * 1);
                    
                    // Update the recoupment user object in the splits array
                    splits[recoupmentUserIndex] = {
                        ...recoupmentUser,
                    recoupment: recoupmentUser.recoupment - recoupmentAmount,
                    };

                    minusRevenue += recoupmentAmount
            
                    collaborators.push({
                        userID: recoupmentUser.userID,
                        user: recoupmentUser.user,
                        recoupment: recoupmentUser.recoupment - recoupmentAmount,
                        revenueShare: recoupmentAmount,
                        isrc: track.isrc,
                        percentage: recoupmentUser.share,
                        title: track.track,
                        isRecoupment: true
                    });

                    track.revenue = trackRevenue - recoupmentAmount

                    // const remainingAmount = trackRevenue - recoupmentAmount;
                    if (trackRevenue > 0) {
                        const matchingSplits = splits.filter(split => split.track === track.isrc);
                        
                        // Calculate and update revenue share for each split
                        let count = 0
                        let totalShares = 0
                        let remainPercentage = 100
                        matchingSplits.forEach(split => {
                            count++
                            const revenueShare = (trackRevenue * split.share) / 100;
                            totalShares += revenueShare
                            remainPercentage -= split.share * 1
                            
                            // Add collaborator to the collaborators array
                            collaborators.push({
                                userID: split.userID,
                                user: split.user,
                                recoupment: 0,
                                revenueShare,
                                isrc: track.isrc,
                                percentage: split.share,
                                title: track.track,
                                isRecoupment: false
                            });
                        });
                        if (count === matchingSplits.length) {
                            track.revenue = trackRevenue - totalShares;
                            track.share = remainPercentage
                            minusRevenue += totalShares
                        }
                    }
                    
                } else {
                    const matchingSplits = splits.filter(split => split.track === track.isrc);
                    
                    // Calculate and update revenue share for each split
                    let count = 0
                    let totalShares = 0
                    let remainPercentage = 100
                    matchingSplits.forEach(split => {
                        count++
                        const revenueShare = (trackRevenue * split.share) / 100;
                        totalShares += revenueShare
                        remainPercentage -= split.share * 1
                        
                        // Add collaborator to the collaborators array
                        collaborators.push({
                            userID: split.userID,
                            user: split.user,
                            recoupment: 0,
                            revenueShare,
                            isrc: track.isrc,
                            percentage: split.share,
                            title: track.track,
                            isRecoupment: false
                        });
                    });
                    if (count === matchingSplits.length) {
                        track.revenue = trackRevenue - totalShares;
                        track.share = remainPercentage
                        minusRevenue += totalShares
                    }
                }
            
            
            // Find splits for the current track by matching ISRC
          });
        });
      
        // Return the updated releases array and the collaborators array
        return { releases, collaborators, minusRevenue };
    }


    const getTrackTotalRevenue = (releases, targetISRC) => {
        // Iterate through the releases
        for (const release of releases) {
            // Iterate through the tracks in the current release
            for (const track of release.tracks) {
                // Check if the track has the specified ISRC
                if (track.isrc === targetISRC) {
                    // Return the revenue of the track
                    return track.revenue;
                }
            }
        }
        // Return null if the track with the specified ISRC is not found
        return null;
    }

    const updateWithRecoupment = (track, splits, payout, trackTotalRevenue) => {
        const collaborators = [];
        let minusRevenue = 0
        
        let newTrackTotalRevenue = trackTotalRevenue * payout
        let trackRevenue = track['Earnings'] * payout
        const recoupmentUserIndex = splits.findIndex(
            (split) => split.track === track['ISRC'] && split.recoupment * 1 > 0
        );

        if (recoupmentUserIndex !== -1) {

            const recoupmentUser = splits[recoupmentUserIndex]
            const recoupmentAmount = recoupmentUser.recoupment * 1
    
            if (recoupmentAmount >= newTrackTotalRevenue) {
                track['Remain After Recoup'] = 0;
            } else {
                const recoupmentPercentage = recoupmentAmount * 100 / newTrackTotalRevenue
        
                const matchingSplits = splits.filter(split => split.track === track['ISRC']);
                
                // Calculate and update revenue share for each split
                let totalShares = 0
                let remainPercentage = 100
        
                const revenueShare = (trackRevenue * recoupmentPercentage) / 100;
                trackRevenue -= revenueShare
                totalShares += revenueShare
                remainPercentage -= recoupmentPercentage * 1
                
                // Add collaborator to the collaborators array
                collaborators.push({
                    userID: recoupmentUser.userID,
                    user: recoupmentUser.user,
                    recoupment: 0,
                    revenueShare,
                    isrc: track['ISRC'],
                    percentage: recoupmentPercentage,
                    title: track.track,
                    isRecoupment: false
                });
        
            }
            
        }
        
        track['Remain After Recoup'] = trackRevenue;


            
            
            // Find splits for the current track by matching ISRC
      
        // Return the updated releases array and the collaborators array
        return track
    }

    const calculateRemainingAmount = (track, splits) => {
        const collaborators = [];
        let minusRevenue = 0

        // Iterate through releases
            // Iterate through tracks in each release

                let trackRevenue = track['Remain After Recoup'] * 1

                const matchingSplits = splits.filter(split => split.track === track['ISRC']);
                
                // Calculate and update revenue share for each split
                let count = 0
                let totalShares = 0
                let remainPercentage = 100
                // let remainAmount = track['Earnings'] * 1
                matchingSplits.forEach(split => {
                    count++
                    const revenueShare = (trackRevenue * split.share) / 100;
                    trackRevenue -= revenueShare
                    totalShares += revenueShare
                    remainPercentage -= split.share * 1
                    
                    // Add collaborator to the collaborators array
                    collaborators.push({
                        userID: split.userID,
                        user: split.user,
                        recoupment: 0,
                        revenueShare,
                        isrc: track['ISRC'],
                        percentage: split.share,
                        title: track.track,
                        isRecoupment: false
                    });
                });
                if (count === matchingSplits.length) {
                    track['Remain'] = trackRevenue;
                }
            
            
            // Find splits for the current track by matching ISRC
      
        // Return the updated releases array and the collaborators array
        return track
    }

    const checkDuplicateMonths = (email, firstArray, secondArray) => {
        // Extract months from each array
        const firstMonths = firstArray.map(report => report.monthYear);
        const secondMonths = secondArray.map(report => report.monthYear);
    
        // Find duplicate months
        const duplicateMonths = secondMonths.filter(month => firstMonths.includes(month));
    
        // If duplicates are found, return error message
        if (duplicateMonths.length > 0) {
            // return `Error: The following months already exist in the first array: ${duplicateMonths.join(', ')}`;
            return `The user ${email} already has reports for the following months: ${duplicateMonths.join(', ')}.`
        } else {
            return null
            // return null; // No duplicates found
        }
    }
    

    const generateId = () => {
        const min = 100000; // Minimum 6-digit number
        const max = 999999; // Maximum 6-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
    } 


    // const checkNewReports = (userId) => {
    //     get(child(ref(db, `users_reports`), userId))
    //     .then(snapshot => {
    //         if (snapshot.exists()) {
    //             return snapshot.val().reports
    //         } else {
    //             return []
    //         }
    //     })
    // };

    // const update

    async function downloadJSONFile(fileName) {
        const fileRef = sRef(storage, `reports/${fileName}`);
      
        try {
          const url = await getDownloadURL(fileRef);
          const response = await fetch(url);
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error('Error downloading JSON file:', error);
          return null; // Return null if download fails
        }
    }

    async function uploadReportsToFirebase(reports, monthYear) {
        const fileName = `${monthYear}.json`;
        const jsonData = JSON.stringify(reports, null, 2);
        const jsonBlob = new Blob([jsonData], { type: 'application/json' });
        const fileRef = sRef(storage, `reports/${fileName}`);
      
        try {
            const uploadTask = uploadBytesResumable(fileRef, jsonBlob);
            uploadTask.on('state_changed', 
                (snapshot) => {
                    // Get progress percentage
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setReportsUpdateProgress(progress)
                }, 
                (error) => {
                    // Handle unsuccessful uploads
                    console.error('Error uploading JSON to Firebase Storage:', error);
                }, 
                () => {
                    // Handle successful uploads
                    console.log(`${fileName} uploaded successfully to Firebase Storage!`);
                    setReportsUpdateSuccess(true);
                }
            );
        } catch (error) {
          console.error('Error uploading JSON to Firebase Storage:', error);
        }
    }

    const handleUpdateReports = async (newReports) => {
        const fileName = 'monthly_reports.json';
    
        try {
            const existingReports = await downloadJSONFile(fileName);
        
            if (existingReports === null || existingReports.length === 0) {
                // No reports uploaded yet, initialize with new reports
                console.log("No reports found. Initializing with new reports.");
                await uploadReportsToFirebase(newReports); // Upload new reports
            } else {
                const updatedReports = [...existingReports, ...newReports];
                await uploadReportsToFirebase(updatedReports); // Upload updated reports
            }
        } catch (error) {
            console.error("Error updating reports: ", error); // Handle the error
        } finally {
            setReportsUpdateSuccess(true);
        }
    };

    function groupInviteSplits(arr1, arr2) {
        const mergedArray = [...arr1, ...arr2];
    
        const grouped = mergedArray.reduce((acc, item) => {
            if (!acc[item.isrc]) {
                acc[item.isrc] = { ...item };
            } else {
                acc[item.isrc].revenueShare += item.revenueShare;
            }
            return acc;
        }, {});
    
        return Object.values(grouped);
    }
    

    const updateReports = () => {

        const newUsers = [...users]

        const usersRef = ref(db, "users");
        setLoader(true)
        return get(usersRef)
          .then((usersSnapshot) => {
            if (usersSnapshot.exists()) {
              const usersData = usersSnapshot.val();
              let count = 0
              let users_reports = []
      
              // Iterate through each user synchronously using Object.entries
              Object.entries(usersData).forEach(([userId, user]) => {
                count++
                if (user.releases) {
                    const date = new Date()
                    const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`
                    const userLiveReleases = user.releases.filter(item => item.wasLive = true)
                    const upcs = userLiveReleases.map(release => release.upc * 1)
                    const userPayout = (user.payoutPercentage * 1) / 100
                    const totalPrices = reports
                    .filter(report => report['UPC'] && upcs.includes(report['UPC'].replace(/'/g, '') * 1));
                    const userUpdatedReports = []

                    if (totalPrices.length > 0) {

                        const groupedReleases = groupReleases(totalPrices, userPayout)
                        const groupedTracks = groupTracksByISRC(groupedReleases)

                        const totalsByMonthYear = {};

                        totalPrices.forEach(report => {
                            const statementPeriod = report["Statement Period"];
                            
                            const [year, month] = statementPeriod.split('-').map(Number);
                        
                            let reportMonth;
                        
                            // Format the month and year for the report
                            reportMonth = `${month}/${year}`;
                        
                            const monthYear = reportMonth;

                            const netRevenue = report['Earnings'] * userPayout;

                            report['User ID'] = user.userID
                            
                            // if (user.splits === undefined) {
                                totalsByMonthYear[monthYear] = (totalsByMonthYear[monthYear] || 0) + netRevenue;
                                report['Remain'] = netRevenue
                                userUpdatedReports.push(report)
                            //     const trackTotalRevenue = getTrackTotalRevenue(groupedTracks, report['ISRC'])
                            //     const updatedWithRecoupment = updateWithRecoupment(report, user.splits, userPayout, trackTotalRevenue)
                            //     if (updatedWithRecoupment['Remain After Recoup'] === 0) {
                            //         report['Remain'] = 0
                            //         userUpdatedReports.push(report)
                            //     } else {
                            //         const remaining = calculateRemainingAmount(updatedWithRecoupment, user.splits)
                            //         userUpdatedReports.push(remaining)
                            //         totalsByMonthYear[monthYear] = (totalsByMonthYear[monthYear] || 0) + remaining['Remain'] * 1;
                            //     }
                            //     // totalsByMonthYear[monthYear] = (totalsByMonthYear[monthYear] || 0) + remainingPrice(user.splits, report['Earnings'], report.isrc);
                            // }

                            // set(push(ref(db, 'uploaded_reports')), report)
                            users_reports.push(report)
                        });

                        const monthlyTotalArray = Object.entries(totalsByMonthYear).map(([monthYear, totalPrice]) => ({
                            monthYear,
                            totalPrice
                        }));

                        let revenue = monthlyTotalArray.reduce((total, item) => total + item.totalPrice * 1, 0)

                        const topChannels = sortChannels(totalPrices, userPayout, user.sorting?.channels || [])
                        const topReleases = sortReleases(totalPrices, userPayout, user.sorting?.releases || [])
                        const topCountries = sortCountries(totalPrices, userPayout, user.sorting?.countries || [])

                        let groupedReports = []
                        if (user.splits !== undefined) {
                            const userShares = calculateRevenueShares(groupedTracks, user.splits, userPayout)
                            revenue -= userShares.minusRevenue
                            monthlyTotalArray[monthlyTotalArray.length - 1].totalPrice -= userShares.minusRevenue
                            groupedReports = userShares.releases
                            const collabsList = groupCollaborators(userShares.collaborators)
                            collabsList.forEach(collabUser => {
                                const inviteSplitsUser = users.findIndex(item => item.userID === collabUser.key)
                                if (inviteSplitsUser !== -1) {
                                    const grouppedSplits = groupInviteSplits(users[inviteSplitsUser].inviteSplits || [], collabUser.tracks || [])
                                    update(child(ref(db, 'users'), users[inviteSplitsUser].id), {
                                        balance: collabUser.balance + (users[inviteSplitsUser].balance * 1 || 0),
                                        inviteSplits: grouppedSplits
                                    })
                                    newUsers[inviteSplitsUser].balance = collabUser.balance + (newUsers[inviteSplitsUser].balance * 1 || 0)
                                }
                            })  
                        } else {
                            groupedReports = groupedTracks
                        }



                        // get(child(ref(db, `users_reports`), userId))
                        // .then(snapshot => {
                        //     if (snapshot.exists()) {

                        //         const userNewReports = snapshot.val().reports
                        //         const newUpdatedReports = [...userNewReports, ...userUpdatedReports]

                        //         update(child(ref(db, 'users_reports'), userId), {
                        //             reports: newUpdatedReports
                        //         })

                        //     } else {

                        //         set(child(ref(db, 'users_reports'), userId), {
                        //             reports: userUpdatedReports
                        //         })
                                
                        //     }
                        // })

                        // for (let i = 0; i < userUpdatedReports.length; i += 1000) {
                        //     const batch = userUpdatedReports.slice(i, i + 1000);
                        //     axios.post('https://bne-backend.vercel.app/saveReports', {
                        //         reports: batch,
                        //         userId: userId
                        //     })
                        // }

                        let userReportsOverview = {}
                        const userReportsOverviewObject = overviewObjects.find(item => item.email === user.email)
                        if (userReportsOverviewObject) {
                            userReportsOverview = {
                                netRevenue: userReportsOverviewObject.totalNetRevenue * 1,
                                grossRevenue: userReportsOverviewObject.totalGrossRevenue * 1,
                                streams: userReportsOverviewObject.totalStreams * 1,
                                monthYear: users_reports[0]["Statement Period"]
                            }
                        }

                        const newUserReportsOverview = [...user.reportsOverview || [], userReportsOverview]

                        const currentUser = newUsers.findIndex(item => item.id === userId)
                        const userBalance = newUsers[currentUser].balance * 1 || 0
                        
                        // const userNewReports = checkNewReports(userId)
                        const newReports = [...user.reports || [], ...monthlyTotalArray]
                        const sortedArray = sortArrayByMonthYear(newReports)
                        const groupedReleasesResult = mergeReleases(groupedReports, user.groupedReleases || [])

                        ///////////
                        update(child(ref(db, 'users'), userId), {
                            reports: sortedArray,
                            sorting: {
                                channels: topChannels,
                                releases: topReleases,
                                countries: topCountries
                            },
                            groupedReleases: groupedReleasesResult,
                            balance: userBalance + revenue * 1,
                            splits: user.splits || [],
                            reportsOverview: newUserReportsOverview
                        })

                        newUsers[currentUser].balance = userBalance + revenue * 1

                    }
                }
                if (count === Object.entries(usersData).length) {
                    uploadReportsToFirebase(users_reports, users_reports[0]["Statement Period"])
                    const payeeReports = [...reports]
                    for (let i = 0; i < payeeReports.length; i++) {
                        const reportUPC = payeeReports[i]['UPC'].replace('\'', '');
                        let reportPayee = ''
                        users.forEach(user => {
                            let hasUPC = false
                            if (user.releases) {
                                user.releases.forEach(release => {
                                    if (release.upc === reportUPC) {
                                        hasUPC = true
                                    }
                                })
                            }
                            if (hasUPC === true) {
                                if (user.payeeID) {
                                    reportPayee = user.payeeID
                                }
                            }
                        })
                        payeeReports[i]['Payee ID'] = reportPayee
                    }
                    const CSVData = convertArrayToCsv(payeeReports)
                    uploadCSV(CSVData, generateId())
                }
              });
            } else {
              console.log("No users found in the database.");
              setLoader(false)
            }
          })
          .catch((error) => {
            console.error("Error updating reports:", error);
            setLoader(false)
          });
    }

    const filterAdminReports = (reports) => {
        return reports.filter(report => {
            // Find the release that matches the report's UPC
            const matchingRelease = releases.find(release => release.upc * 1 === report['UPC'].replace(/'/g, '') * 1);
            if (!matchingRelease) {
                // If no matching release is found, exclude the report
                return false;
            }
      
            // Check if the report's ISRC exists in the matching release's tracks
            const matchingTrack = matchingRelease.tracks.find(track => track.isrc === report['ISRC']);
            if (matchingTrack) {
                // If both UPC and ISRC are valid, set 'Release Title' and 'Track Title'
                report['Release Title'] = matchingRelease.title;
                report['Track Title'] = matchingTrack.title;
                return true; // Include the report
            }
            return false; // Keep the report if ISRC exists
        });
    };

    const updateAdminReports = () => {
    
        // Iterate through each user synchronously using Object.entries
        const date = new Date()
        const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`
        const totalPrices = filterAdminReports(reports)
        // .reduce((total, report) => total + report.taxesnet_total_client_currency * 1, 0);

        const totalsByMonthYear = {};

        totalPrices.forEach(report => {

            const statementPeriod = report["Statement Period"];
                            
            const [year, month] = statementPeriod.split('-').map(Number); 
        
            let reportMonth;
        
            // Format the month and year for the report
            reportMonth = `${month}/${year}`;
        
            const monthYear = reportMonth;

            totalsByMonthYear[monthYear] = (totalsByMonthYear[monthYear] || 0) + report['Earnings'] * 1;

        });

        const monthlyTotalArray = Object.entries(totalsByMonthYear).map(([monthYear, totalPrice]) => ({
            monthYear,
            totalPrice
        }));

        const balance = monthlyTotalArray.reduce((total, item) => total + item.totalPrice * 1, 0) + adminBalance * 1

        const topChannels = sortAdminChannels(totalPrices)
        const topReleases = sortAdminReleases(totalPrices)
        const topCountries = sortAdminCountries(totalPrices)

        const groupedReleases = groupAdminReleases(totalPrices)
        const groupedTracks = groupAdminTracksByISRC(groupedReleases)


        
        const sortedArray = sortArrayByMonthYear(monthlyTotalArray)
        ///////////
        update(child(ref(db, 'admin'), 'reports'), {
            reports: [...adminReports, ...sortedArray],
            sorting: {
                channels: topChannels,
                releases: topReleases,
                countries: topCountries
            },
            groupedReleases: groupedTracks,
            balance: balance,
        })
    }


    const handleOverview = (reports) => {
        const usersRef = ref(db, "users");
          
      
        return get(usersRef)
          .then((usersSnapshot) => {
            if (usersSnapshot.exists()) {
              const usersData = usersSnapshot.val();
              let count = 0
              let newOverviewItems = []

      
              // Iterate through each user synchronously using Object.entries
              Object.entries(usersData).forEach(([userId, user]) => {

                count++
                if (user.releases) {
                    const date = new Date()
                    const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`
                    const userLiveReleases = user.releases.filter(item => item.wasLive === true)
                    const upcs = userLiveReleases.map(release => release.upc * 1)
                    const userPayout = (user.payoutPercentage * 1) / 100
                    const totalPrices = reports
                        .filter(report => report['UPC'] && upcs.includes(report['UPC'].replace(/'/g, '') * 1));
                        
                    if (totalPrices.length > 0) {

                        const totalsByMonthYear = {};

                        let errorsObject = {}

                        totalPrices.forEach(report => {

                            const statementPeriod = report['Statement Period']; 
                                    
                            const [year, month] = statementPeriod.split('-').map(Number); 

                            const relevantRelease = userLiveReleases.find(item => item.upc * 1 === report['UPC'].replace(/'/g, '') * 1)


                            // if (relevantRelease.tracks) {
                                const relevantReleaseTracks = relevantRelease.tracks || []
                                const relevantTrack = relevantReleaseTracks.find(item => item.isrc?.trim() === report['ISRC']?.trim())
                                if (relevantTrack) {
                                    if (relevantTrack.title !== report['Track Title']) {
                                        errorsObject[`The track ${relevantTrack.isrc} title doesn't match`] = true
                                    }
                                } else {
                                    errorsObject[`The track ${report['ISRC']} doesn't exist in the release ${relevantRelease.upc}`] = true
                                    console.log(relevantRelease, report)
                                    // setErrors((prevErrors) => [...prevErrors, `The track ${report['ISRC']} doesn't exist in the release ${relevantRelease.upc}`])
                                }
                            // }
                        
                            let reportMonth;
                        
                            // Format the month and year for the report
                            reportMonth = `${month}/${year}`;
                        
                            const monthYear = reportMonth;

                            const netRevenue = report['Earnings'] * userPayout;
                            const grossRevenue = report['Earnings'] * 1
                        
                            totalsByMonthYear[monthYear] = {
                                netPrice: (totalsByMonthYear[monthYear] && totalsByMonthYear[monthYear].netPrice || 0) + netRevenue,
                                grossPrice: (totalsByMonthYear[monthYear] && totalsByMonthYear[monthYear].grossPrice || 0) + grossRevenue,
                                totalStreams: (totalsByMonthYear[monthYear] && totalsByMonthYear[monthYear].totalStreams || 0) + report['Quantity'] * 1
                            };

                        });
                        
                        setErrors((prevErrors) => [...prevErrors, ...Object.keys(errorsObject)])
    
                        const monthlyTotalArray = Object.entries(totalsByMonthYear).map(([monthYear, values]) => ({
                            monthYear,
                            netPrice: values.netPrice,
                            grossPrice: values.grossPrice,
                            totalStreams: values.totalStreams
                        }));

                        // console.log(checkDuplicateMonths(user.email, user.reports || [], monthlyTotalArray))
                        const monthError = checkDuplicateMonths(user.email, user.reports || [], monthlyTotalArray)
                        if (monthError !== null) {
                            setErrors((prevErrors) => [...prevErrors, monthError])
                        }
    
                        let netRevenue = monthlyTotalArray.reduce((total, item) => total + item.netPrice * 1, 0)
                        const grossRevenue = monthlyTotalArray.reduce((total, item) => total + item.grossPrice * 1, 0)
                        const streams = monthlyTotalArray.reduce((total, item) => total + item.totalStreams * 1, 0)

                        if (user.splits !== undefined) {
                            const groupedReleases = groupReleases(totalPrices, userPayout)
                            const groupedTracks = groupTracksByISRC(groupedReleases)
                            const userShares = calculateRevenueShares(groupedTracks, user.splits, userPayout)
                            netRevenue -= userShares.minusRevenue
                        }
    
                        const newOverviewItem = {
                            email: user.email,
                            payeeID: user.payeeID || '',
                            name: `${user.firstName} ${user.lastName}`,
                            totalStreams: streams,
                            totalNetRevenue: netRevenue.toFixed(2),
                            totalGrossRevenue: grossRevenue.toFixed(2)
                        }

                        newOverviewItems.push(newOverviewItem)
                        // setOverviewObjects(prevObjects => [newOverviewItem, ...prevObjects])
                    }
                }
                if (count === Object.entries(usersData).length) {
                    console.log(newOverviewItems)
                    setOverviewObjects(newOverviewItems)
                }

              });
            } else {
              console.log("No users found in the database.");
            }
          })
          .catch((error) => {
            console.error("Error updating reports:", error);
          });
    }
    
    
    const handleHistory = () => {

        const reportsArray = filterAdminReports(reports)

        const totalsByMonthYear = {};
        const rowsNumber = reportsArray.length
      
        reportsArray.forEach(report => {
            const statementPeriod = report["Statement Period"];
            
            const [year, month] = statementPeriod.split('-').map(Number);
        
            let reportMonth;
        
            // Format the month and year for the report
            reportMonth = `${month}/${year}`;
        
            const monthYear = reportMonth;
        
            totalsByMonthYear[monthYear] = {
                totalPrice: (totalsByMonthYear[monthYear] && totalsByMonthYear[monthYear].totalPrice || 0) + report['Earnings'] * 1,
                totalStreams: (totalsByMonthYear[monthYear] && totalsByMonthYear[monthYear].totalStreams || 0) + report['Quantity'] * 1
            };
        });
        

        const monthlyTotalArray = Object.entries(totalsByMonthYear).map(([monthYear, values]) => ({
            monthYear,
            totalPrice: values.totalPrice,
            totalStreams: values.totalStreams
        }));

        const result = monthlyTotalArray.reduce((accumulator, currentValue) => {
            // Extracting monthYear, totalStreams, and totalPrice from the current object
            const { monthYear, totalStreams, totalPrice } = currentValue;
        
            // Accumulating totalStreams and totalPrice
            accumulator.totalStreams += totalStreams;
            accumulator.totalPrice += totalPrice;
        
            // Concatenating monthYear with comma
            accumulator.monthYears.push(monthYear);
        
            return accumulator;
        }, { monthYears: [], totalStreams: 0, totalPrice: 0 });
        

        result.monthYears.sort();
        result.monthYears = result.monthYears.join(', ');
        result.rows = rowsNumber
        
        return result
    }


    useEffect(() => {
        if (reportsUploadSuccess === true && reportsUpdateSuccess === true) {
            setLoader(false)
            reportsSuccess.current.click()
        }
    }, [reportsUploadSuccess, reportsUpdateSuccess])

    const handleAppear = (properties) => {
        headers.map((item, i) => {
            const existingHeader = properties.find(property => property === item.mapping)
            if (existingHeader) {
                handleMapp(existingHeader, i)
            } else {
                handleMapp('Select Header', i)
            }
            setUploadReportsMessage('Upload Reports')
        })
    }

    const handleReportsUpload = (event) => {
        const file = event.target.files[0];
        setFileUpload(file)
        setUploadReportsMessage('Please Wait...')
        setDropdowns(true)
        if (file) {
            Papa.parse(file, {
                complete: (result) => {

                    setFileReports(result.data)
                    const properties = Object.keys(result.data[0])
                    setFileHeaders(properties)
                    handleAppear(properties)
                },
                header: true, // Set this to true if your CSV file has a header row
            });
        }
    }

    const handleMapp = (header, index) => {
        let newHeaders = [...headers]
        newHeaders[index].uploaded = header
        setHeaders(newHeaders)
    }

    function convertArrayToCsv(reportsArray) {
        const header = Object.keys(reportsArray[0]).join(',') + '\n';
        const rows = reportsArray.map(item => Object.values(item).join(',')).join('\n');
        return header + rows;
    }
      
    // Function to upload CSV file to Firebase Storage
    async function uploadCSV(csvData, fileName) {
        try {
            // Specify the desired path in Firebase Storage
            const storageRef = sRef(storage, `reports/${fileName}.csv`);
    
            const csvBlob = new Blob([csvData], { type: 'text/csv' });

            // Start the upload using `uploadBytesResumable` for progress tracking
            const uploadTask = uploadBytesResumable(storageRef, csvBlob);
    
            // Monitor the upload progress
            uploadTask.on('state_changed', 
                (snapshot) => {
                    // Calculate the upload progress percentage
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setReportsUploadProgress(progress); // Update progress in your state
                }, 
                (error) => {
                    // Handle unsuccessful uploads
                    console.error('Error uploading CSV to Firebase Storage:', error);
                }, 
                async () => {
                    // Handle successful uploads and get the download URL
                    const downloadURL = await getDownloadURL(storageRef);
    
                    // Save the download URL and other data to Firebase Realtime Database
                    await set(push(ref(db, 'reports')), {
                        file: downloadURL,
                        ...handleHistory(), // Additional data
                        overview: overviewObjects // Additional data
                    });
    
                    // Update the loader state and trigger the success action
                    setReportsUploadSuccess(true)
                }
            );
        } catch (error) {
            console.error('Error uploading CSV file:', error);
        }
    }

      

    const validateFile = (data) => {
        let count = 0;
        let hasErrors = false;
    
        data.forEach((item, i) => {
            count++;
    
            if (item['UPC'] === undefined || item['UPC'] === '') {
                setErrors((prevErrors) => [...prevErrors, `Missing UPC in row ${i + 2}`]);
                hasErrors = true;
            }
            if (item['Quantity'] === undefined || item['Quantity'] === '') {
                setErrors((prevErrors) => [...prevErrors, `Missing units in row ${i + 2}`]);
                hasErrors = true;
            }
            if (item['Earnings'] === undefined || item['Earnings'] === '') {
                setErrors((prevErrors) => [...prevErrors, `Missing revenue in row ${i + 2}`]);
                hasErrors = true;
            }
        });
    
        if (!hasErrors) {
            handleOverview(data);            
        } else {
            // Click the errorBtn if there are errors
            errorBtn.current.click();
        }
    };

    const updateHeaders = () => {
        const updatedDataArray = fileReports.map(dataObject => {
            const updatedObject = {};
            headers.forEach(header => {
              const { mapping, uploaded } = header;
              updatedObject[mapping] = dataObject[uploaded];
            });
            return updatedObject;
        });
        setReports(updatedDataArray)
        validateFile(updatedDataArray)
    }

    const dropVerify = headers.find(item => item.uploaded === 'Select Header')


    async function uploadAsCSV() {

        try {

            setLoader(true)

            const CSVData = convertArrayToCsv(reports)

            const fileName = generateId()
            // Specify the desired path in Firebase Storage
            const storageRef = sRef(storage, `reports/${fileName}.csv`);

            // Upload the CSV data as a raw string with the correct content type
            await uploadString(storageRef, CSVData, 'raw', { contentType: 'text/csv' });

            // Get the download URL for the uploaded file
            const downloadURL = await getDownloadURL(storageRef);

            // Save the download URL and other data to the Firebase Realtime Database
            console.log(downloadURL)

            // axios.post('https://bne-backend.vercel.app/uploadReports', {
            axios.post('http://localhost:5000/uploadReports', {
                reportsURL: downloadURL,
            }).then(() => {
                console.log('Success')
                updateReports()
                updateAdminReports()
            })


        } catch (error) {
            console.error('Error uploading CSV file:', error);
        }
    }


  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Upload Reports</h1>
            <div className="row">
                <div className="col-lg-4 px-1 mt-4">
                    <div className="dash-form-file py-4">
                        <i className="fi fi-rr-cloud-upload"></i>
                        {uploadReportsMessage}
                        <input type="file" onChange={handleReportsUpload} />
                        <button data-bs-toggle="modal" data-bs-target="#reportsModal" ref={reportsSuccess} hidden></button>
                    </div>
                </div>
                
                <div className="col-lg-8 px-1 mt-4"></div>
                {
                    dropdowns && (
                        <>
                            <div className="col-12 mt-4">
                                {
                                    headers.map((item, i) => (
                                        <div className="row" key={i}>
                                            <div className="col-md-6 mb-2 px-1">
                                                <h6 className='mapping-base'>{item.title}</h6>
                                            </div>
                                            <div className="col-md-6 mb-2 px-1">
                                                <div className="dropdown dash-form-dropdown">
                                                    <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {item.uploaded}
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        {
                                                            fileHeaders.map((header, index) => (
                                                                <li key={index} onClick={() => handleMapp(header, i)}>{header}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="row">
                                    <div className="col-12 px-1 d-flex justify-content-end mt-3">
                                        <button 
                                            className={`dash-card-heading-btn ${dropVerify && 'disabled'}`} 
                                            disabled={dropVerify ? true : false}
                                            onClick={updateHeaders}
                                        >
                                            Update Headers
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
                {
                    overviewObjects.length > 0 && (
                        <div className="col-12 px-1 mt-4">
                            <h4>Reports Overview</h4>
                            <div className="table-responsive mt-4">
                                <table className="table dash-table">
                                    <thead>
                                        <tr>
                                            <td>Email</td>
                                            <td>Name</td>
                                            <td>Total Streams</td>
                                            <td>Net Revenue</td>
                                            <td>Gross Revenue</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            overviewObjects.map((user, i) => (
                                                <tr key={i}>
                                                    <td>{user.email}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.totalStreams}</td>
                                                    <td>${user.totalNetRevenue}</td>
                                                    <td>${user.totalGrossRevenue}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-12 px-1 d-flex justify-content-end mt-3">
                                {
                                    errors.length === 0 && <button 
                                        className='dash-card-heading-btn'
                                        onClick={() => {updateAdminReports(); updateReports();}}
                                    >
                                        Submit
                                    </button>
                                }
                                {
                                    errors.length > 0 && <button 
                                        className='dash-card-heading-btn'
                                        data-bs-toggle="modal" 
                                        data-bs-target="#errorModal"
                                    >
                                        Submit
                                    </button>
                                }
                            </div>
                        </div>
                    )
                }
            </div>


        </div>




        <div className="modal fade" id="reportsModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="reportsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/reports">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Reports Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

        <button data-bs-toggle="modal" data-bs-target="#errorModal" ref={errorBtn} hidden></button>
        <div className="modal fade" id="errorModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="errorModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal pb-3">
                        <a 
                            href={'/admin/reports'} 
                            className='success-close-btn' 
                            // data-bs-dismiss="modal"
                        >
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        {
                            errors.map((item, i) => (
                                <h6 className='error-item' key={i}>{item}</h6>
                            )) 
                        }
                    </div>
                </div>
            </div>
        </div>

        <div className={`loader-container flex-column ${loader && 'active'}`}>
            <div className="loader"></div>
            <div className="upload-progress"><div style={{ width: `${(reportsUploadProgress + reportsUpdateProgress) / 2}%` }}></div></div>
        </div>

    </div>
  )
}

export default AdminReports